 import {
   AfterContentInit,
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation
  } from '@angular/core';
  import AOS from 'aos';
  import { Slick } from 'ngx-slickjs';
  import { FormBuilder, FormGroup, Validators } from '@angular/forms';
  import { CommonService } from '../services/common.service';
  import { ToastrService } from 'ngx-toastr';
  import { ModalOptions } from 'ngx-bootstrap/modal';
  import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
  import { OwlOptions } from 'ngx-owl-carousel-o';
  import { Subscription } from 'rxjs';
  import { Title, Meta } from '@angular/platform-browser';
  import { CanonicalService } from '../services/canonical.service';
import { Router } from '@angular/router';
import { CourseService } from '../services/course.service';
import { EnquiryService } from '../services/enquiry.service';
import { TestimonialService } from '../services/testimonial.service';
import { AlumniService } from '../services/alumni.service';


  @Component({
    selector: 'app-home-new',
    templateUrl: './home-new.component.html',
    styleUrls: ['./home-new.component.css'],

  })
  export class HomeNewComponent implements OnInit,AfterContentInit {
    title = 'Navigate your career in code with the ultimate educational destination!';
    modalConfig: ModalOptions = {
      animated: true,
      keyboard: false,
      backdrop: 'static',
      ignoreBackdropClick: true
    };
    show: boolean = false;
    fullScreen: boolean = true;
    template = ``;
    items!: GalleryItem[];
    showspinner: boolean = false;
    showspinnerPast: boolean = false;
    public eventsUpcoming: any[] = [];
    public eventsPast: any[] = [];
    listCourseValue:any;
    isProcessing:boolean=false;
    listTestimonyValue: any;
    listAlumniValue:any;
    currentPage = 1;
    courses = ['Certified Blockchain Professional', 'UX/UI Design Specialist', 'Responsive Web Design Specialist', 'Professional Certification Course in MEAN Stack'];
    videoId: string = 'wsO9CtaLPxQ';
    currentVideoId!: any;
    @ViewChild('videoYT') player: any;
    currentDeleteID: string = ''
    currentUrl!: any;
    showModal: boolean = false;
    firstClick:any=0;
    focusedField: string | null = null; 
    events_upcoming = [
      {
        previewImg: 'assets/images/excel.png',
        PreviewAlt: 'Excel',
        partnerLogo: 'assets/images/logo-excel.png',
        eventDate: 'Fri, Mar 17, 11:30 AM',
        eventName: 'Decentralized Finance (DeFi)'
      },
      {
        previewImg: 'assets/images/krishna.png',
        PreviewAlt: 'krishna',
        partnerLogo: 'assets/images/logo-krishna.png',
        eventDate: 'Thu, Mar 23, 02:00 PM',
        eventName: 'Getting started with iOS Career'
      },
      {
        previewImg: 'assets/images/jansons.png',
        PreviewAlt: 'jansons',
        partnerLogo: 'assets/images/logo-jansons.png',
        eventDate: 'Tue, Apr 06, 10:30 AM',
        eventName: 'Artificial Intelligence in Testing'
      },
      {
        previewImg: 'assets/images/kg.png',
        PreviewAlt: 'kg',
        partnerLogo: 'assets/images/logo-kg.png',
        eventDate: 'Sat, Apr 15, 11:00 AM',
        eventName: 'Effective Email Marketing Techniques'
      },
      {
        previewImg: 'assets/images/excel.png',
        PreviewAlt: 'excel',
        partnerLogo: 'assets/images/logo-excel.png',
        eventDate: 'Fri, Mar 17, 11:30 AM',
        eventName: 'Decentralized Finance (DeFi)'
      },
      {
        previewImg: 'assets/images/krishna.png',
        PreviewAlt: 'krishna',
        partnerLogo: 'assets/images/logo-krishna.png',
        eventDate: 'Thu, Mar 23, 02:00 PM',
        eventName: 'Getting started with iOS Career'
      }
    ];
    events_pasts = [
      {
        previewImg: 'assets/images/excel.png',
        PreviewAlt: 'Excel',
        partnerLogo: 'assets/images/logo-excel.png',
        eventDate: 'Fri, Mar 17, 11:30 AM',
        eventName: 'Decentralized Finance (DeFi)'
      },
      {
        previewImg: 'assets/images/krishna.png',
        PreviewAlt: 'krishna',
        partnerLogo: 'assets/images/logo-krishna.png',
        eventDate: 'Thu, Mar 23, 02:00 PM',
        eventName: 'Getting started with iOS Career'
      },
      {
        previewImg: 'assets/images/jansons.png',
        PreviewAlt: 'jansons',
        partnerLogo: 'assets/images/logo-jansons.png',
        eventDate: 'Tue, Apr 06, 10:30 AM',
        eventName: 'Artificial Intelligence in Testing'
      },
      {
        previewImg: 'assets/images/kg.png',
        PreviewAlt: 'kg',
        partnerLogo: 'assets/images/logo-kg.png',
        eventDate: 'Sat, Apr 15, 11:00 AM',
        eventName: 'Effective Email Marketing Techniques'
      },
      {
        previewImg: 'assets/images/excel.png',
        PreviewAlt: 'excel',
        partnerLogo: 'assets/images/logo-excel.png',
        eventDate: 'Fri, Mar 17, 11:30 AM',
        eventName: 'Decentralized Finance (DeFi)'
      },
      {
        previewImg: 'assets/images/krishna.png',
        PreviewAlt: 'krishna',
        partnerLogo: 'assets/images/logo-krishna.png',
        eventDate: 'Thu, Mar 23, 02:00 PM',
        eventName: 'Getting started with iOS Career'
      }
    ];
    @Input() iValue: number = 500;  
    @Input() certificate:number = 10;
    @Input() tValue:number= 500;
    @Input() hValue:number= 200;
    @Input() duration: number = 5000;  
    @Input() mentors:number = 80;
    @Input() student:number =1500;
    internValue: number = 0;
    certificateValue:number =0;
    teamValue:number=0;
    hiringValue:number=0;
    mentorValue:number=0;
    studenValue:number=0;
    config: Slick.Config = {
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 1000,
      responsive: [
        {
          breakpoint: 1024, // tablet breakpoint
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576, // mobile breakpoint
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    videotestimonials: Slick.Config = {
      infinite: true,
      initialSlide: 1,
      slidesToShow: 1,
      slidesToScroll: 2,
      dots: false,
      arrows: true,
      autoplay: false,
      variableWidth: true,
      autoplaySpeed: 0,
      speed: 3000,
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 991, // tablet breakpoint
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576, // mobile breakpoint
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    studenttestimonials: Slick.Config = {
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 0,
      focusOnSelect: true,
      speed: 20000,
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 991, // tablet breakpoint
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576, // mobile breakpoint
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    studenttestimonialsrtl: Slick.Config = {
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 0,
      focusOnSelect: true,
      speed: 20000,
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 991, // tablet breakpoint
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576, // mobile breakpoint
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    upcomingEvents: Slick.Config = {
      //infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: true,
      //variableWidth: true,
      autoplaySpeed: 3000,
      //speed: 10000,
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 991, // tablet breakpoint
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576, // mobile breakpoint
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    pastEvents: Slick.Config = {
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: true,
      //variableWidth: true,
      autoplaySpeed: 3000,
      //speed: 10000,
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 991, // tablet breakpoint
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576, // mobile breakpoint
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    public contactForm!: FormGroup;
    submitted = false;
    countryCode: any;
    // country_code: any;
    // formdata = [];
      // Store subscription to manage it properly
      private submitFormSubscription: Subscription | undefined;

    constructor(
      private formBuilder: FormBuilder,
      private commonService: CommonService,
      private toastr: ToastrService,
      public gallery: Gallery,
      private titleService: Title,
      private meta: Meta,
      private canonicalService: CanonicalService,
      private router:Router,
      private courseService:CourseService,
      private enquiryService:EnquiryService,
      private testimonial:TestimonialService,
      private alumniService:AlumniService
    ) { }

    ngOnInit(): void {
      
      AOS.init();
      this.eventsUpcoming = this.events_upcoming;
      this.contactForm = this.formBuilder.group({
        name: ['', {
          validators: [Validators.required, Validators.minLength(3)],
          updateOn: 'blur'
        }],
        organization: ['', {
          validators: [Validators.required],
          updateOn: 'blur'
        }],
        email: ['', {
          validators: [Validators.required, Validators.email,Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ )],
          updateOn: 'blur'
        }],
        phone_number: [
          '',{
          validators:  [
              Validators.required,
              Validators.minLength(10),
              Validators.maxLength(16),
              Validators.pattern(/^\d{10,16}$/)
            ],
          updateOn: 'blur'  
          }
        ],
        enquiry: ['',{
          validators: [Validators.required],
          updateOn: 'blur'
        }],
        message: [''],
      });

      this.items = data.map(item =>
        new ImageItem({ src: item.srcUrl, thumb: item.previewUrl })
      );

      // Load items into the lightbox
      this.basicLightboxExample();

      // Load item into different lightbox instance
      // With custom gallery config
      this.withCustomGalleryConfig();


      this.titleService.setTitle(this.title);
    this.meta.updateTag({ name: 'description', content: "Codespot is a nonprofit educational organization, that facilitates upskilling and learning opportunities for youths. As an educational foundation, we aim at providing technological skills for students, for which we host and conduct different levels of programs and training as a part of our Education empowerment. As a part of our Educational and skill development programs, we offer internships to upskill and hand the roots of technology to everyone!" });
    this.meta.updateTag({ name: 'keywords', content: 'Educational Foundation, Nonprofit Education Organization, Educational Initiatives, Education Support Programs, Learning Opportunities, Education Empowerment, Education Enrichment Programs, Education Awareness, Education and Skills Development, Education and Training Programs, technological skills for students' });
    this.meta.updateTag({ name: 'author', content: 'CodeSpot Foundation | Sparkouttech' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ property: 'og:title', content: 'Navigate your career in code with the ultimate educational destination!' });
    this.meta.updateTag({ property: 'og:site_name', content: 'CodeSpot Foundation | Sparkouttech' });
    this.meta.updateTag({ property: 'og:url', content: 'https://codespotfoundation.org/home-new/' });
    this.meta.updateTag({ property: 'og:description', content: "Codespot is a nonprofit educational organization, that facilitates upskilling and learning opportunities for youths. As an educational foundation, we aim at providing technological skills for students, for which we host and conduct different levels of programs and training as a part of our Education empowerment. As a part of our Educational and skill development programs, we offer internships to upskill and hand the roots of technology to everyone!" });
    this.meta.updateTag({ property: 'og:type', content: "Codespot is a nonprofit educational organization, that facilitates upskilling and learning opportunities for youths. As an educational foundation, we aim at providing technological skills for students, for which we host and conduct different levels of programs and training as a part of our Education empowerment. As a part of our Educational and skill development programs, we offer internships to upskill and hand the roots of technology to everyone!" });
    this.meta.updateTag({ property: 'og:image', content: 'https://www.codespotfoundation.org/assets/images/codespot-og.png' });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: 'https://codespotfoundation.org/home-new/' });
    this.meta.updateTag({ name: 'twitter:title', content: 'Navigate your career in code with the ultimate educational destination!' });
    this.meta.updateTag({ name: 'twitter:description', content: "Codespot is a nonprofit educational organization, that facilitates upskilling and learning opportunities for youths. As an educational foundation, we aim at providing technological skills for students, for which we host and conduct different levels of programs and training as a part of our Education empowerment. As a part of our Educational and skill development programs, we offer internships to upskill and hand the roots of technology to everyone!" });
    this.meta.updateTag({ name: 'twitter:image', content: 'https://www.codespotfoundation.org/assets/images/codespot-og.png' });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL("https://codespotfoundation.org/home-new/");

    this.courseListDetail();
    this.listAlumni();
    this.listTestimonial();

    }
    ngAfterContentInit():void{
      this.internCounter();
      this.certificationCounter();
      this.teamCounter();
      this.hiringpartnerCounter();
      this.mentorCounter();
      this.studentCounter();
    }
    /**
     * Stops video
     */
    // public stopVideo() {
    //   var videos = document.querySelectorAll('iframe, video');
    //   Array.prototype.forEach.call(videos, function (video) {
    //     if (video.tagName.toLowerCase() === 'video') {
    //       video.pause([0]);
    //     } else {
    //       var src = video.src;
    //       video.src = src;
    //     }
    //   });
    // }

    basicLightboxExample() {
      this.gallery.ref().load(this.items);
    }
    /**
     * Use custom gallery config with the lightbox
     */
    withCustomGalleryConfig() {

      // 2. Get a lightbox gallery ref
      const lightboxGalleryRef = this.gallery.ref('anotherLightbox');

      // (Optional) Set custom gallery config to this lightbox
      lightboxGalleryRef.setConfig({
        imageSize: ImageSize.Cover,
        thumbPosition: ThumbnailsPosition.Top
      });

      // 3. Load the items into the lightbox
      lightboxGalleryRef.load(this.items);
    }

    //easy to access form control value
    get f() {
      return this.contactForm.controls;
    }

    //for focussing the input field while form validation
    onFocus(fieldName: string){
      this.focusedField = fieldName;
    }

    onBlur() {
      this.focusedField = null;
    }

    onSubmit() {
      //console.log(this.contactmail)
      this.submitted = true;
      if (this.contactForm.valid) {
        let formData = {
          ...this.contactForm.value,
          country_code: this.countryCode
        };

         // Unsubscribe if there's a previous subscription to prevent memory leaks
         if (this.submitFormSubscription) {
          this.submitFormSubscription.unsubscribe();
        }
        //console.log(formdata);
        this.submitFormSubscription = this.commonService.submitcontactform(formData).subscribe({
          next: (response: any) => {
            this.submitted = false;
            if ((response.status = ['SUCCESS'])) {
              this.toastr.success('Mail Send Successfully!', 'Success!');
              this.contactForm.reset();
            }
          },
          error: (error: any) => {
            this.submitted = false;
            this.toastr.error('Failed to send mail. Please try again later.','Error!');
          }
      });
      }
    }
    numericOnly(event: any) {
      let pattern = /^([0-9])$/;
      let result = pattern.test(event.key);
      return result;
    }

    ngOnDestroy() {
      // Clean up subscription to prevent memory leaks
      if (this.submitFormSubscription) {
        this.submitFormSubscription.unsubscribe();
      }
    }

    onClickUpcoming() {
      this.showspinner = true;
      this.eventsUpcoming = [];
      setTimeout(() => {
        this.eventsUpcoming = this.events_upcoming.slice();
        this.showspinner = false
      }, 3000);
    }
    onClickPast() {
      this.showspinnerPast = true;
      this.eventsPast = []
      setTimeout(() => {
        this.eventsPast = this.events_pasts.slice();
        this.showspinnerPast = false
      }, 3000);
    }
    //video owl carousel
      customOptions: OwlOptions = {
      loop: true,
      margin:20,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      dots: false,
      autoplay:true,
      nav:true,
      navSpeed:3000,
      navText: ['<i class="bi bi-arrow-left"></i>', '<i class="bi bi-arrow-right"></i>'],
      responsive: {
        0: {
          items: 1
        },
        740: {
          items: 2
        },
        940: {
          items: 3
        }
      }
    }

     /**
   * Courses list detail
   */
  public courseListDetail(){

    this.courseService.listCourseDetail().subscribe({
      next: (response: any) => {
       this.listCourseValue=response.data.docs;

        this.listCourseValue = this.listCourseValue.filter((listCourse:any)=> this.courses.includes(listCourse?.title))

      },
      error: (error: any) => {
        console.log(error)

      }
    })
  }
    /**
  * Lists alumni
  */
    public listAlumni() {

      this.alumniService.listAlumni().subscribe({
        next: (response: any) => {
          this.listAlumniValue = response.data;

        },
        error: (error: any) => {
          console.error('list alumni error', error.error.message);

        }
      })
    }

  /**
   * Lists testimonial
   */
  public listTestimonial() {

    this.testimonial.listTestimonial(this.currentPage).subscribe({
      next: (response: any) => {
        this.listTestimonyValue = response.data.docs;

      },
      error: (error: any) => {

      }
    })
  }
  /**
   * Creates contact detail
   */
  public createContactDetail(){
    this.isProcessing=true;
    const params={
      name: this.contactForm.value.name,
      organization: this.contactForm.value.organization,
      email: this.contactForm.value.email,
      phone_number: String(this.contactForm.value.phone_number),
      enquiry :this.contactForm.value.enquiry


    }


    this.enquiryService.createContact(params).subscribe({
    next:(response:any)=>{
      this.isProcessing=false;
      this.toastr.success('Enquiry Sent');
    this.contactForm.reset();

      },

    error:(error:any)=>{
     this.toastr.error('Enquiry Failed');
     this.isProcessing=false;
  }
})

}


    /**
 * Navigates course listing component
 * @param url
 * @param id
 */
// public navigate(url:string, id:string):void{

//   this.router.navigateByUrl(`${url}/${id}`)
// }

public navigate(url: string, id: string): void {
  window.open(`${url}/${id}`, '_blank');
}

/**
 * Smoothly scrolls the page to the section with the specified ID.
 *
 * @param {string} sectionId - The ID of the section to scroll to.
 * @returns {void}
 */
scrollToSection(sectionId:string):void{
  const section:HTMLElement|null = document.getElementById(sectionId);
  section?.scrollIntoView({ behavior: 'smooth' });
}
/**
   * Gets you tube url
   * @param videoId
   */
getYouTubeUrl(videoId: string) {
  this.currentVideoId = videoId
   this.currentUrl = `https://www.youtube.com/embed/${videoId}`
   document.getElementById('videoYT')!.innerHTML = videoId;
  if( this.firstClick==0)
    {
    setTimeout(() => {
      this.showModal = !this.showModal;
    }, 1900);
  }
  else
  {
    this.showModal = !this.showModal;
  }

  this.firstClick++;
}

stopVideo(data: any) {
  this.showModal = !this.showModal
  this.currentUrl = ''
  // if (this.player) {
  //   this.player.nativeElement.contentWindow.postMessage('{"event":"pause"}', '*');
  // }
  // document.getElementById('videoYT')!.innerHTML = this.currentVideoId;
}

// Function to be called by the YouTube IFrame API (optional)
onPlayerReady(event: any) {
  event.target.playVideo(); // You can autoplay here if desired
}

/**
 * Opens modal
 * @param data
 */
openModal(data: any) {

  this.currentUrl = ''
  this.currentVideoId = data;
  this.getYouTubeUrl(this.currentVideoId)
  // this.modalConfig.show()
}

/**
 * Removes content
 */
removeContent() {
  console.log(this.player.nativeElement.contentWindow,"Content")
  var iframeContent = document.getElementById('videoYT')
  let valueRemove = this.player.nativeElement?.contentWindow?.document;
  console
  valueRemove.documentElement.innerHTML = "";
}

/**
 * Interns counter
 */
internCounter() {
  const startValue = 0;
  const range = this.iValue - startValue;
  const stepTime = Math.abs(Math.floor(this.duration / range));
  let current = startValue;
  const timer = setInterval(() => {
    current += 1;
    this.internValue = current;
    if (current === this.iValue) {
      clearInterval(timer);
    }
  }, stepTime);
}
certificationCounter() {
  const startValue = 0;
  const range = this.certificate - startValue;
  const stepTime = Math.abs(Math.floor(this.duration / range));
  let current = startValue;
  const timer = setInterval(() => {
    current += 1;
    this.certificateValue = current;
    if (current === this.certificate) {
      clearInterval(timer);
    }
  }, stepTime);
}

/**
 * Teams counter
 */
teamCounter() {
  const startValue = 0;
  const range = this.tValue - startValue;
  const stepTime = Math.abs(Math.floor(this.duration / range));
  let current = startValue;
  const timer = setInterval(() => {
    current += 1;
    this.teamValue = current;
    if (current === this.tValue) {
      clearInterval(timer);
    }
  }, stepTime);
}

/**
 * Hiringpartners counter
 */
hiringpartnerCounter() {
  const startValue = 0;
  const range = this.hValue - startValue;
  const stepTime = Math.abs(Math.floor(this.duration / range));
  let current = startValue;
  const timer = setInterval(() => {
    current += 1;
    this.hiringValue = current;
    if (current === this.hValue) {
      clearInterval(timer);
    }
  }, stepTime);
}

/**
 * Mentors counter
 */
mentorCounter(){
  const startValue = 0;
  const range = this.mentors - startValue;
  const stepTime = Math.abs(Math.floor(this.duration / range));
  let current = startValue;
  const timer = setInterval(() => {
    current += 1;
    this.mentorValue = current;
    if (current === this.mentors) {
      clearInterval(timer);
    }
  }, stepTime);
}

/**
 * Students counter
 */
studentCounter(){
  const startValue = 0;
  const range = this.student - startValue;
  const stepTime = Math.abs(Math.floor(this.duration / range));
  let current = startValue;
  const timer = setInterval(() => {
    current += 1;
    this.studenValue = current;
    if (current === this.student) {
      clearInterval(timer);
    }
  }, stepTime);
}
  //ourAlumni owl carousel
  ourAlumni: OwlOptions = {
    loop: true,
    margin:30,
    autoplay: true,
    autoWidth:true,
    slideTransition: 'linear',
    autoplayTimeout: 0,
    autoplaySpeed: 6000,
    autoplayHoverPause:true,
    dots: false,
  
  };
  

}

  const data = [
    {
      srcUrl: 'assets/images/gallery1.png',
      previewUrl: 'assets/images/gallery1.png'
    },
    {
      srcUrl: 'assets/images/gallery2.png',
      previewUrl: 'assets/images/gallery2.png'
    },
    {
      srcUrl: 'assets/images/gallery3.png',
      previewUrl: 'assets/images/gallery3.png'
    },
    {
      srcUrl: 'assets/images/gallery4.png',
      previewUrl: 'assets/images/gallery4.png'
    },
    {
      srcUrl: 'assets/images/gallery5.png',
      previewUrl: 'assets/images/gallery5.png'
    },
    {
      srcUrl: 'assets/images/gallery6.png',
      previewUrl: 'assets/images/gallery6.png'
    },
    {
      srcUrl: 'assets/images/gallery7.png',
      previewUrl: 'assets/images/gallery7.png'
    },
    {
      srcUrl: 'assets/images/gallery8.png',
      previewUrl: 'assets/images/gallery8.png'
    },
    {
      srcUrl: 'assets/images/gallery9.png',
      previewUrl: 'assets/images/gallery9.png'
    }
  ];

