<app-header-new></app-header-new>
<section class="main">
  <section class="courselisting-banner main-banner">
    <div class="container">
      <div class="row align-items-end align-items-lg-center">
        <div class="col-md-6 position-relative">
          <div class="">
            <div class="star center-70">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <img data-src="assets/images/star.png" width="35" height="35" alt="Star"
                  class="img-fluid animate-blink" loading="lazy">
              </picture>
            </div>
            <h1 class="text-white"> Empowering <span class="border-0">Young Minds</span> to Grow </h1>
            <p class="text-white fs-6">We provide an equitable education to all students and work towards acquiring
              skills & knowledge that will prepare them for the future in technology. We provide an equitable education.
            </p>
            <div class="d-flex mb-100">
              <p  class="request_btn btn-style-one" (click)="scrollToSection('contact')">Join Us <i
                  class="bi bi-arrow-right"></i>
              </p>
            </div>
            <div class="star bottom-70">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <img data-src="assets/images/star.png" width="35" height="35" alt="Star"
                  class="img-fluid animate-blink" loading="lazy">
              </picture>
            </div>
          </div>
        </div>
        <div class="col-md-6 position-relative text-center">
          <div class="star top-70">
            <picture>
              <source type="image/webp" srcset="assets/images/star.webp">
              <img data-src="assets/images/star.png" width="35" height="35" alt="Star"
                class="img-fluid animate-blink" loading="lazy">
            </picture>
          </div>
          <picture>
            <source type="image/webp" srcset="assets/images/banner.webp">
            <img src="assets/images/banner.png" width="546" height="441" alt="banner-pattern"
              class="img-fluid">
          </picture>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="our-story pt-150" id="our-story">
    <div class="container">
      <div class="row">
        <div class="col-md-6 sm-pb-40">
          <div class="title">
            <div class="star top-70">
              <picture>
                <source type="image/svg" srcset="assets/images/star.svg">
                <img src="assets/images/star.png" width="35" height="35" alt="Star"
                  class="img-fluid animate-blink" loading="lazy">
              </picture>
            </div>
            <div class="h4">Our Story</div>
            <h2> A Thought For <span>Change <br /> & Inclusion </span>
            </h2>
            <p> Our journey with Codespot Foundation started early on, when we
              were searching for talent. We noticed a disconnect when we had to train freshers who we hired for various
              domains. We understood that students find it hard to be employable due to the lack of awareness about the
              IT Industry. Our initiative took birth from a desire to change this disconnect. </p>
          </div>
          <!-- <div class="d-flex" data-aos="fade-up" data-aos-delay="900"><a class="request_btn btn-style-one" href="#"
              >Our Journey
              <i class="bi bi-arrow-right"></i></a><a class="request_btn btn-style-two" href="#"
              >See Our Podcasts <i class="bi bi-play-circle"></i
            ></a></div>
        </div>
        <div class="col-md-6">
          <div class="card d-flex flex-row align-items-center">
            <picture>
              <source type="image/svg" srcset="assets/images/icon-lack-awareness.svg">
              <!-- <source type="image/png" srcset="assets/images/icon-lack-awareness.png">
              <img src="assets/images/icon-lack-awareness.svg" width="50" height="50"
                alt="Lack of awareness" class="img-fluid w-67 d-flex" loading="lazy">
            </picture>
            <!-- <img src="assets/images/icon-lack-awareness.png" alt="Lack of awareness" class="img-fluid w-67 d-flex" loading="lazy" />
            <div class="d-flex flex-column pl-25 wcalc50">
              <h3>Lack of <span>awareness </span>
              </h3>
              <p> India is diverse and tech awareness still hasn’t trickled down to the grass-root level. </p>
            </div>
          </div>
          <div class="card d-flex flex-row align-items-center">
            <picture>
              <source type="image/svg" srcset="assets/images/icon-knowledge.svg">
              <!-- <source type="image/png" srcset="assets/images/icon-knowledge.png">
              <img src="assets/images/icon-knowledge.svg" width="50" height="50"
                alt="Knowledge Disconnect" class="img-fluid w-67 d-flex" loading="lazy">
            </picture>
            <!-- <img src="assets/images/icon-knowledge.png" alt="Knowledge Disconnect" class="img-fluid w-67 d-flex" loading="lazy" />
            <div class="d-flex flex-column pl-25 wcalc50">
              <h3>
                <span>Knowledge </span> Disconnect
              </h3>
              <p> Students cannot learn contemporary skills through school curriculums alone. </p>
            </div>
          </div>
          <div class="card d-flex flex-row align-items-center">
            <picture>
              <source type="image/svg" srcset="assets/images/icon-learn-exposure.svg">
              <!-- <source type="image/png" srcset="assets/images/icon-learn-exposure.png">
              <img src="assets/images/icon-learn-exposure.svg" width="50" height="50"
                alt="Learn of Exposure" class="img-fluid w-67 d-flex" loading="lazy">
            </picture>
            <!-- <img src="assets/images/icon-learn-exposure.png" alt="Learn of Exposure" class="img-fluid w-67 d-flex" loading="lazy" />
            <div class="d-flex flex-column pl-25 wcalc50">
              <h3>
                <span>Learn </span> of Exposure
              </h3>
              <p> Students do not have exposure to industry standards, organizational structures, and domains. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <section class="grow-student-careers text-center pt-150">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="title d-inline-block">
            <h2>
              We help students scale up their <span>Careers.</span></h2>
            <p class="text-center">
              We provide students with the opportunity to explore and succeed,
              so that they can grow and achieve <br />
              in their careers & goals. Here are some paths to follow:
            </p>
            <div class="star left-bottom">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <img src="assets/images/star.png" width="35" height="35" alt="Star"
                  class="img-fluid animate-blink" loading="lazy">
              </picture>
            </div>
            <div class="star right-top">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <img src="assets/images/star.png" width="35" height="35" alt="Star"
                  class="img-fluid animate-blink" loading="lazy">
              </picture>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 py-md-4">
          <div class="card"><img src="assets/images/icon-involvement.svg" alt="teach"
              class="img-fluid w-67 m-auto" width="67" height="66" />
            <h3>Active Involvement</h3>
            <p class="pb-3 pb-md-0">
              We encourage individuals to set ambitious goals, create plans, and diligently work towards them with our guidance.
            </p>
          </div>
        </div>
        <div class="col-md-4 py-md-4">
          <div class="card"><img src="assets/images/icon-growth.svg"
              alt="growth" class="img-fluid w-67 m-auto" width="67" height="68" />
            <h3>Learners Expansion</h3>
            <p class="pb-3 pb-md-0">
              Tailoring education to individual learning styles, enabling knowledge, practical skills, and real-world experience.
            </p>
          </div>
        </div>
        <div class="col-md-4 py-md-4">
          <div class="card">
            <img src="assets/images/icon-goal.svg" alt="money" class="img-fluid w-67 m-auto" width="67"
              height="68" loading="lazy" />
            <h3>Goal Attainment</h3>
            <p class="pb-3 pb-md-0">
              We encourage students to actively engage in their learning journey, including sessions, projects, and discussions.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="mission-vision pt-150">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <figure class="sticky">
            <picture>
              <source type="image/webp" srcset="assets/images/mission.webp">
              <source type="image/png" srcset="assets/images/mission.png">
              <img src="assets/images/mission.png" width="551" height="432" alt="mission"
                class="img-fluid animate-float" loading="lazy">
            </picture>
            <!-- <img src="assets/images/mission.png" alt="mission" class="img-fluid" loading="lazy" />
          </figure>
        </div>
        <div class="col-md-6">
          <div class="mission">
            <div class="title">
              <div class="h4">Our Mission</div>
              <h2> Your <span>destination</span> for <br /> success &
                <span>creativity!</span>
              </h2>
            </div>
            <p> To bridge the gap between industry skills and education for 53%
              of Indian graduates who don’t have access to quality resources. </p>
            <p> To build a thriving talent pool in India for businesses to scale, grow and innovate in the competitive global market.</p>
            <p> To contribute to India’s rapid development in education,
              technology, and infrastructure through universal inclusion. </p>
            <p> To accelerate this vision through Tamil Nadu, utilizing the
              state’s rich educational infrastructure. </p>
          </div>
          <div class="mission vision">
            <div class="title">
              <div class="h4">Our Vision</div>
              <h2>To empower minds that shapes <span>the future</span>
              </h2>
            </div>
            <p> Empowering students early by collaborating with various
              educational institutions in India, starting with Tamil Nadu. </p>
            <p> Providing diverse opportunities for hands-on industry training and practical
              placements. </p>
            <p> Inspire a generation of youngsters to continuosly strive for more and achieve greater heights. </p>
            <p> Encourage students to upskill with essential tools, empowering the next generation of leaders and change-makers.
             </p>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <section class="programs pt-150" id="courses">
    <div class="container">
      <div class="row">
        <div class="col-md-6 sm-pb-40">
          <div class="title">
            <div class="h4">Courses</div>
            <h2> Elevate Your Skills to the<br /> <span>Next Level</span>
            </h2>
            <div class="star right-bottom">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <img src="assets/images/star.png" width="35" height="35" alt="Star"
                  class="img-fluid animate-blink" loading="lazy">
              </picture>
            </div>
          </div>
          <p> We offer a lot of sessions, seminars, and workshops to students that can be a valuable part of a student's education, as they can gain a deeper understanding of the subject matter, develop new skills, build confidence, network with others, gain exposure to different perspectives, and access resources that can supplement their learning.  </p>
          <p class="pb-54"> This is a convenient learning objective planned for students from all backgrounds. Students open new opportunities & gain a competitive edge in their chosen field </p>
          <div class="d-flex">
            <a class="request_btn btn-style-one" routerLink="/course-listing">See All Courses <i
                class="bi bi-arrow-right"></i>
            </a>
            <!-- <a class="request_btn btn-style-two" href="#">See Our Podcasts <i class="bi bi-play-circle"></i>
            </a> -->
          </div>
        </div>
        <div class="col-md-6 d-md-block d-none">
          <figure class="m-0">
            <picture>
              <source type="image/webp" srcset="assets/images/programs.webp">
              <img src="assets/images/programs.png" width="547" height="575" alt="mission-bg"
                class="img-fluid animate-float" loading="lazy">
            </picture>
            <!-- <img src="assets/images/programs.png" alt="mission-bg" class="img-fluid" loading="lazy" /> -->
          </figure>
        </div>
      </div>
    </div>
  </section>
 <!-- course list-->
 <section class="course-wrap mb-0">
  <div class="container">
      <div class="row align-items-end">
          <div class="col-12 col-md-6">
              <div class="title">
                <div class="h4">Courses</div>
                <h2 class="p-0"> Accelerate your career</h2>
              </div>
          </div>
          <div class="col-12 col-md-6 position-relative">
              <div class="star top-70">
                  <picture>
                    <source type="image/webp" srcset="assets/images/star.webp">
                    <img loading="lazy" src="assets/images/star.png" width="35" height="35" alt="Star" class="img-fluid animate-blink">
                  </picture>
              </div>

          </div>
      </div>
      <div class="course-section mb-0">
          <div class="row pb-2" >
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 my-1 my-md-3" *ngFor="let data of listCourseValue">
                  <div (click)="navigate('course-detail', data.slug)" class="cursor-pointer">
                      <picture>
                          <source type="image/webp" [srcset]="data.image_url">
                          <img loading="lazy" [attr.data-src]="data.image_url" [alt]="data.title" alt="Course Image" width="261" height="144" class="w-100 rounded-top">
                      </picture>
                  </div>
                  <div class="course-part">
                      <span class="d-block">3 Months</span>
                      <h3 (click)="navigate('course-detail', data.slug)" class="cursor-pointer">{{data.title}}</h3>
                      <ul class="p-0 m-0 d-flex  flex-column flex-lg-row justify-content-between">
                          <li class="d-flex align-items-center gap-1">
                              <img loading="lazy" src="assets/images/course-listing/icon-1.svg" alt="Blockchain Translate" width="20" height="20" class="d-inline-block">
                              <p class="p-0">Tamil & English</p>
                          </li>
                          <li class="d-flex align-items-center gap-1">
                              <img src="assets/images/course-listing/icon-2.svg" alt="Blockchain Enrolled" width="20" height="20" class="d-inline-block ">
                              <p class="p-0">{{data.enroll_count}} Enrolled</p>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="d-flex justify-content-center">
            <a class="request_btn btn-style-one" routerLink="/course-listing">See All Courses <i
                class="bi bi-arrow-right"></i>
            </a>
          </div>
      </div>
  </div>
</section>


  <!--up skilling since 2022-->
  <section class="listfoundation-section pt-150">
    <div class="container">
      <div class="title d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3">
        <div class="d-flex flex-column">
          <div class="star left-top">
            <picture>
              <source type="image/webp" srcset="assets/images/star.webp">
              <img src="assets/images/star.png" width="35" height="35" alt="Star"
                class="img-fluid animate-blink" loading="lazy">
            </picture>
          </div>
          <!-- <div class="h4">Courses</div>
          <h2>Accelerate your <span>career</span></h2> -->
        </div>

      </div>

        <div class="title d-flex justify-content-center">
          <h2 class="pb-0 mb-md-5 mb-4 text-center">
            Upskilling since 2022
          </h2>
          </div>
      <div class="row row-cols-2 row-cols-sm-2 row-cols-lg-4">
        <div class="col smb-3">
           <div class="countview">
               <h5>{{ internValue }} <span>+</span></h5>
               <h3> {{ internValue }} <span>+</span></h3>
               <h4>Interns</h4>
           </div>
        </div>
        <div class="col smb-3">
           <div class="countview">
               <h5>{{ mentorValue }} <span>+</span></h5>
               <h3>{{mentorValue}} <span>+</span></h3>
               <h4>Mentors</h4>
           </div>
        </div>
        <div class="col smb-3">
           <div class="countview">
               <h5>{{ studenValue }} <span>+</span></h5>
               <h3>{{studenValue}} <span>+</span></h3>
               <h4>Students</h4>
           </div>
         </div>
        <div class="col smb-3">
           <div class="countview">
               <h5>{{ certificate }} <span>+</span></h5>
               <h3>{{certificate}} <span>+</span></h3>
               <h4>Certifications</h4>
           </div>
       </div>
     </div>
    </div>
  </section>

 <!--For college students,For school students,For Fresher-->
 <section class="our-story tabs tabs-section pt-150" id="why-codespot">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="position-relative">

          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="fw-semibold nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"> #Codespot <h3>For College
                  Students</h3>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="fw-semibold nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false"> #Codespot <h3>For
                  School Students</h3>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="fw-semibold nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane"
                type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false"> #Codespot <h3>For
                  Freshers</h3>
              </button>
            </li>
          </ul>
        </div>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
            tabindex="0">
            <div class="row">
              <div class="col-md-5 sm-pb-40 d-flex align-items-center justify-content-center">
                <!-- <div class="star left-bottom">
                  <picture>
                    <source type="image/webp" srcset="assets/images/star.webp">
                    <img src="assets/images/star.png" width="35" height="35" alt="Star"
                      class="img-fluid animate-blink" loading="lazy">
                  </picture>
                </div> -->
                <picture>
                  <source type="image/webp" srcset="assets/images/experts.webp">
                  <img src="assets/images/experts.png" width="413" height="461" alt="experts"
                    class="img-fluid animate-float" loading="lazy">
                </picture>
                <!-- <img src="assets/images/experts.png" alt="experts" class="img-fluid animate-float" loading="lazy" /> -->
              </div>
              <div class="col-md-7 pt-50 pl-50">
                <div class="card d-flex flex-row align-items-center">
                  <picture>
                    <source type="image/webp" srcset="assets/images/icon-awareness-sessions.webp">
                    <img src="assets/images/icon-awareness-sessions.png" width="50" height="50" alt="Sessions"
                      class="img-fluid w-67 d-flex" loading="lazy">
                  </picture>
                  <!-- <img src="assets/images/icon-sessions.png" alt="Sessions" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                  <div class="d-flex flex-column pl-25 wcalc50">
                    <h4>Sessions, Workshops & Hackathon</h4>
                    <p class="pb-0"> Students gain valuable insights and learn new programming languages tools & techniques. </p>
                  </div>
                </div>
                <div class="card d-flex flex-row align-items-center">
                  <picture>
                    <source type="image/webp" srcset="assets/images/interview.webp">
                    <img src="assets/images/interview.png" width="50" height="50"
                      alt="Interaction" class="img-fluid w-67 d-flex" loading="lazy">
                  </picture>
                  <!-- <img src="assets/images/icon-interaction.png" alt="Interaction" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                  <div class="d-flex flex-column pl-25 wcalc50">
                    <h4>Interaction with industry leaders</h4>
                    <p class="pb-0"> Interacting with industry leaders can provide students with potential prospects & exposure </p>
                  </div>
                </div>
                <div class="card d-flex flex-row align-items-center">
                  <picture>
                    <source type="image/webp" srcset="assets/images/carrier-guidance.webp">
                    <img src="assets/images/carrier-guidance.png" width="50" height="50"
                      alt="Industry Access" class="img-fluid w-67 d-flex" loading="lazy">
                  </picture>
                  <!-- <img src="assets/images/icon-industry.png" alt="Industry Access" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                  <div class="d-flex flex-column pl-25 wcalc50">
                    <h4>Carrier Guidance & Job Opportunity</h4>
                    <p class="pb-0"> Students make informed decisions about their career path & find meaningful employment. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
            <div class="row">
              <div class="col-md-5 sm-pb-40 d-flex align-items-center justify-content-center">
                <picture>
                  <source type="image/webp" srcset="assets/images/for-school-students.webp">
                  <img src="assets/images/for-school-students.png" width="454" height="462"
                    alt="For School Students" class="img-fluid animate-float" loading="lazy">
                </picture>
                <!-- <img src="assets/images/for-school-students.png" alt="For School Students"
                  class="img-fluid animate-float" loading="lazy" /> -->
              </div>
              <div class="col-md-7 pt-50 pl-50">
                <div class="card d-flex flex-row align-items-center">
                  <picture>
                    <source type="image/webp" srcset="assets/images/awareness.webp">
                    <img src="assets/images/awareness.png" width="50" height="50"
                      alt="Awareness Sessions" class="img-fluid w-67 d-flex" loading="lazy">
                  </picture>
                  <!-- <img src="assets/images/icon-awareness-sessions.png" alt="Awareness Sessions"
                    class="img-fluid w-67 d-flex" loading="lazy" /> -->
                  <div class="d-flex flex-column pl-25 wcalc50">
                    <h4>Awareness Sessions</h4>
                    <p class="pb-0"> Students are introduced to the possibilities and opportunities that await them in the tech industry. </p>
                  </div>
                </div>
                <div class="card d-flex flex-row align-items-center">
                  <picture>
                    <source type="image/webp" srcset="assets/images/motivational-talks.webp">
                    <img src="assets/images/motivational-talks.png" width="51" height="51"
                      alt="Motivational Talks" class="img-fluid w-67 d-flex" loading="lazy">
                  </picture>
                  <!-- <img src="assets/images/icon-motivational-talks.png" alt="Motivational Talks"
                    class="img-fluid w-67 d-flex" loading="lazy" /> -->
                  <div class="d-flex flex-column pl-25 wcalc50">
                    <h4>Motivational Talks</h4>
                    <p class="pb-0"> Our thought leaders speak with students and help them understand how a career in tech can help them succeed and grow as individuals. </p>
                  </div>
                </div>
                <div class="card d-flex flex-row">
                  <picture>
                    <source type="image/webp" srcset="assets/images/coding-essentials.webp">
                    <img src="assets/images/coding-essentials.png" width="51" height="51"
                      alt="coding Essentials" class="img-fluid w-67 d-flex" loading="lazy">
                  </picture>
                  <!-- <img
                    src="assets/images/icon-money.svg"
                    alt="money"
                    class="img-fluid w-67 d-flex"
                  /> -->
                  <div class="d-flex flex-column pl-25 wcalc50"><h4>Coding Essentials  </h4>
                    <p class="pb-0">
                      A basic coding class for school students will be provided to introduce them to the world of programming and technology.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
            <div class="row">
              <div class="col-md-5 sm-pb-40 d-flex align-items-center justify-content-center">
                <picture>
                  <source type="image/webp" srcset="assets/images/for-freshers.webp">
                  <img src="assets/images/for-freshers.png" width="413" height="448" alt="For Freshers"
                    class="img-fluid animate-float" loading="lazy">
                </picture>
                <!-- <img src="assets/images/for-freshers.png" alt="For Freshers" class="img-fluid animate-float" loading="lazy" /> -->
              </div>
              <div class="col-md-7 pt-50 pl-50">
                <div class="card d-flex flex-row align-items-center">
                  <picture>
                    <source type="image/webp" srcset="assets/images/icon-level1.webp">
                    <img src="assets/images/icon-level1.png" width="50" height="48"
                      alt="Internship - Level" class="img-fluid w-67 d-flex" loading="lazy">
                  </picture>
                  <!-- <img src="assets/images/icon-level1.png" alt="Internship - Level 1" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                  <div class="d-flex flex-column pl-25 wcalc50">
                    <h4>Internship - Level 1 (1 Month)</h4>
                    <p class="pb-0"> Introduction to basic concepts, hands-on training sessions, and direct access to
                      industry leaders at Codespot Foundation . </p>
                  </div>
                </div>
                <div class="card d-flex flex-row align-items-center">
                  <picture>
                    <source type="image/webp" srcset="assets/images/icon-level2.webp">
                    <img src="assets/images/icon-level2.png" width="50" height="44"
                      alt="Internship - Level 2" class="img-fluid w-67 d-flex" loading="lazy">
                  </picture>
                  <!-- <img src="assets/images/icon-level2.png" alt="Internship - Level 2" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                  <div class="d-flex flex-column pl-25 wcalc50">
                    <h4>Internship - Level 2 (2 Months)</h4>
                    <p class="pb-0"> Skill development and industry training by working on real-world projects,
                      monitors and assessed by technical leads. </p>
                  </div>
                </div>
                <div class="card d-flex flex-row align-items-center">
                  <picture>
                    <source type="image/webp" srcset="assets/images/icon-placement.webp">
                    <img src="assets/images/icon-placement.png" width="50" height="50" alt="Placement"
                      class="img-fluid w-67 d-flex" loading="lazy">
                  </picture>
                  <!-- <img src="assets/images/icon-placement.png" alt="Placement" class="img-fluid w-67 d-flex" loading="lazy" /> -->
                  <div class="d-flex flex-column pl-25 wcalc50">
                    <h4>Placement & Certification</h4>
                    <p class="pb-0"> Students who complete the internship successfully get a certificate and best
                      performers get placement opportunities at Codespot Foundation . </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--alumni students-->
<section class="ouralumni-section">
  <div class="container">

      <div class="title">
        <div class="h4">Sucess Stories</div>
          <h2 class="pb-6"><span>Alumni</span> Students</h2>
      </div>
      <div class="row">
          <!-- <owl-carousel-o [options]="ourAlumni"> -->
            <div class="scroller__wrapper">
              <div class="scroller__container">
                <div class="scroller">
                    <div class="scroller__item" carouselSlide *ngFor="let data of listAlumniValue" width="200">
                        <div class="col-12" >
                            <div class="alumni-part" >
                                <img loading="lazy" [srcset]="data.profile_url"  alt="Microsoft Alumni" width="90" height="90" class="rounded-circle">
                                <p class="fw-semibold p-0">{{data.name}}</p>
                                <span class="d-block">{{data.role}}</span>
                                <img loading="lazy" [srcset]="data.company_url"  alt="Microsoft" width="95" height="23" class="company-logo">
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
          <!-- </owl-carousel-o> -->
      </div>
  </div>
</section>
  <!--video section-->
  <section class="pt-150" id="see-our-podcasts">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <div class="title">
            <h2 class="pb-2">Inspiring Testimonials From <span>Our Lads</span>
            </h2>
            <p class="text-center"> The motivation that we look up every day to
              seek and explore, here is the voice of our trainees, <br>sharing their experience at Codespot. </p>
          </div>
        </div>
        <div class="video-testimonials">
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide *ngFor="let data of listTestimonyValue; let i= index">

                 <div  (click)="openModal(data.videos)" >
                    <div class="testimonial-wrap">
                      <!-- <div class="overlay"></div> -->
                      <figure class="thumbnail-img"><img [src]="data?.thumbnail" width="667" height="375"
                          class="img-fluid" alt="testimonials" loading="lazy" /></figure>

                      <div class="video-play">
                        <picture>
                          <source type="image/webp" srcset="../assets/images/play-icon.webp">
                          <img src="../assets/images/play-icon.svg" width="200" height="200" alt="play"
                            class="img-fluid w-48 d-inline-block" loading="lazy">
                        </picture>
                      </div>
                      <!-- <div class="d-flex justify-content-between testimonial-caption">
                        <div class="d-flex">
                          <div class="d-flex flex-column text-left">
                            <!-- comment for now but uncommented in future
                            <div class="h6">{{data.name}}</div>
                            <div class="h6">{{data.role}}</div> -->
                          <!-- </div> -->
                        <!-- </div> -->
                        <!-- <figure class="d-flex ml-auto">
                          <picture>
                            <source type="image/webp" srcset="../assets/images/quote.webp">
                            <source type="image/png" srcset="../assets/images/quote.png">
                            <img src="../assets/images/quote.png" width="32" height="32" alt="teach"
                              class="img-fluid w-32" loading="lazy">
                          </picture>
                        </figure> -->
                      <!-- </div>  -->
                    </div>
                  </div>

              </ng-template>
            </owl-carousel-o>





            <!-- </owl-carousel-o> -->
        </div>
              <!-- <div class="star left-bottom">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <img src="assets/images/star.png" width="35" height="35" alt="Star"
                  class="img-fluid animate-blink" loading="lazy">
              </picture>
            </div> -->
            <!-- <div class="star right-top">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <img src="assets/images/star.png" width="35" height="35" alt="Star"
                  class="img-fluid animate-blink" loading="lazy">
              </picture>
            </div> -->

        </div>
      </div>
    <!-- <div class="video-testimonials ">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let data of listTestimonyValue">
          <div class="col-12" >
              <div class="alumni-part" >
                  <img loading="lazy" [srcset]="data.profile_url"  alt="Microsoft Alumni" width="90" height="90" class="rounded-circle">
                  <p class="fw-semibold p-0">{{data.name}}</p>
                  <span class="d-block">{{data.role}}</span>
                  <img loading="lazy" [srcset]="data.company_url"  alt="Microsoft" width="95" height="23" class="company-logo">
              </div>
          </div>
      </ng-template>

      </owl-carousel-o>

    </div> -->
  </section>


  <section class="student-testimonials text-center pt-150">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="title d-inline-block">
            <h2 class="pb-6"> What Our Students Say <span>About Us</span>
            </h2>
            <p class="text-center"> Students and their abilities help us grow &
              develop and their excellence, success inspire us to <br /> inculcate more in our walks of lives. </p>
            <div class="star left-bottom">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <img src="assets/images/star.png" width="35" height="35" alt="Star"
                  class="img-fluid animate-blink" loading="lazy">
              </picture>
            </div>
            <div class="star right-top">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <img src="assets/images/star.png" width="35" height="35" alt="Star"
                  class="img-fluid animate-blink" loading="lazy">
              </picture>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="mb-32 slicktestmonials">
            <div slickContainer #slickController="slick" [slickConfig]="studenttestimonials" class="studenttestimonials"
              pauseOnHover="true">
              <ng-container>
                <div slickItem>
                  <div class="testimonial-wrap">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <figure>
                          <picture>
                            <source type="image/webp" srcset="assets/images/testimonial-chandrikha.webp">
                            <img src="assets/images/testimonial-chandrikha.png" width="48" height="48"
                              alt="teach" class="img-fluid w-48" loading="lazy">
                          </picture>
                          <!-- <img src="assets/images/testimonial-chandrikha.png" class="w-48" alt="teach" loading="lazy" /> -->
                        </figure>
                        <div class="d-flex flex-column text-left">
                          <div class="h4">Chandrikha Shanmugam</div>
                          <div class="h6">HR Manager</div>
                        </div>
                      </div>
                      <figure class="d-flex ml-auto">
                        <picture>
                          <source type="image/webp" srcset="assets/images/quote.webp">
                          <img src="assets/images/quote.png" width="32" height="32" alt="quote"
                            class="img-fluid w-32" loading="lazy">
                        </picture>
                        <!-- <img src="assets/images/quote.png" class="w-32" alt="teach"  loading="lazy"/> -->
                      </figure>
                    </div>
                    <p> Codespot Foundation has been a turning point in my life. Joining the Codespot Foundation team has
                      been a stepping stone toward my goal of creating products, as well as an incredible journey of
                      learning, networking, and positive change. I got the right guidance from professionals to find a great
                      career & got an opportunity to better position myself as a leading professional in the software
                      industry. </p>
                  </div>
                </div>
                <div slickItem>
                  <div class="testimonial-wrap">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <figure>
                          <picture>
                            <source type="image/webp" srcset="assets/images/testimonial-mythili.webp">
                            <img src="assets/images/testimonial-mythili.png" width="48" height="48"
                              alt="mythili" class="img-fluid w-48" loading="lazy">
                          </picture>
                          <!-- <img src="assets/images/testimonial-mythili.png" class="w-48" alt="teach" loading="lazy" /> -->
                        </figure>
                        <div class="d-flex flex-column text-left">
                          <div class="h4">Mythili Sivakumar</div>
                          <div class="h6">Associate Content Writer</div>
                        </div>
                      </div>
                      <figure class="d-flex ml-auto">
                        <picture>
                          <source type="image/webp" srcset="assets/images/quote.webp">
                          <img src="assets/images/quote.png" width="32" height="32" alt="quote"
                            class="img-fluid w-32" loading="lazy">
                        </picture>
                      </figure>
                    </div>
                    <p> To be in an Understanding, Valuing and Encouraging space is a dream to many. Codespot Foundation,
                      despite me being a new addition, I am experiencing all of that with wider opportunities to learn and
                      explore. And the biggest plus here is, it's a hub of energetic enthusiasts. </p>
                  </div>
                </div>
                <div slickItem>
                  <div class="testimonial-wrap">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <figure>
                          <picture>
                            <source type="image/webp" srcset="assets/images/testimonial-aravindan.webp">
                            <img src="assets/images/testimonial-aravindan.png" width="48" height="48"
                              alt="aravindan" class="img-fluid w-48" loading="lazy">
                          </picture>
                          <!-- <img src="assets/images/testimonial-aravindan.png" class="w-48" alt="teach"  loading="lazy"/> -->
                        </figure>
                        <div class="d-flex flex-column text-left">
                          <div class="h4">Aravindan</div>
                          <div class="h6">Associate Software Developer</div>
                        </div>
                      </div>
                      <figure class="d-flex ml-auto">
                        <picture>
                          <source type="image/webp" srcset="assets/images/quote.webp">
                          <img src="assets/images/quote.png" width="32" height="32" alt="quote"
                            class="img-fluid w-32" loading="lazy">
                        </picture>
                      </figure>
                    </div>
                    <p> Codespot Foundation is the place where I started my career. It helps me build a strong career
                      foundation through a lot of opportunities to explore and learn new technologies. I'm glad to work
                      around such cool ambience and friendly nature management. Codespot Foundation is a place where you can
                      pursue your passion, grow, and develop your skills technically. </p>
                  </div>
                </div>
                <div slickItem>
                  <div class="testimonial-wrap">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <figure>
                          <picture>
                            <source type="image/webp" srcset="assets/images/testimonial-ramseena.webp">
                            <img src="assets/images/testimonial-ramseena.png" width="48" height="48"
                              alt="ramseena" class="img-fluid w-48" loading="lazy">
                          </picture>
                          <!-- <img src="assets/images/testimonial-ramseena.png" class="w-48" alt="teach" loading="lazy" /> -->
                        </figure>
                        <div class="d-flex flex-column text-left">
                          <div class="h4">Ramseena</div>
                          <div class="h6">Associate Content Writer</div>
                        </div>
                      </div>
                      <figure class="d-flex ml-auto">
                        <picture>
                          <source type="image/webp" srcset="assets/images/quote.webp">
                          <img src="assets/images/quote.png" width="32" height="32" alt="quote"
                            class="img-fluid w-32" loading="lazy">
                        </picture>
                      </figure>
                    </div>
                    <p> Codespot Foundation brought my career journey to life, gave me an identity, and propelled me toward
                      recognition. Do you want to stand out from the rest? Then Codespot Foundation will level you up and
                      help you discover your self-identity with good acknowledgment for the work that you do. </p>
                  </div>
                </div>
                <div slickItem>
                  <div class="testimonial-wrap">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <figure>
                          <picture>
                            <source type="image/webp" srcset="assets/images/testimonial-jegan.webp">
                            <img src="assets/images/testimonial-jegan.png" width="48" height="48" alt="jegan"
                              class="img-fluid w-48" loading="lazy">
                          </picture>
                          <!-- <img src="assets/images/testimonial-jegan.png" class="w-48" alt="teach" loading="lazy" /> -->
                        </figure>
                        <div class="d-flex flex-column text-left">
                          <div class="h4">Jegan</div>
                          <div class="h6">Associate Software Developer</div>
                        </div>
                      </div>
                      <figure class="d-flex ml-auto">
                        <picture>
                          <source type="image/webp" srcset="assets/images/quote.webp">
                          <img src="assets/images/quote.png" width="32" height="32" alt="quote"
                            class="img-fluid w-32" loading="lazy">
                        </picture>
                      </figure>
                    </div>
                    <p> Having started my career with Codespot Foundation, I've been working under sociable management for 2
                      years now. I'm happy to work in such a hassle-free environment. My experiences with Codespot
                      Foundation have given me numerous opportunities to turn struggles into new possibilities, which has
                      helped me achieve my goals. </p>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="mb-0 testimonial-post2-rtl">
            <div dir="rtl" slickContainer #slickController="slick" [slickConfig]="studenttestimonialsrtl"
              class="studenttestimonialsrtl" pauseOnHover="true">
              <ng-container>
                <div slickItem>
                  <div class="testimonial-wrap">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <figure>
                          <picture>
                            <source type="image/webp" srcset="assets/images/testimonial-shanmugarajeshwaran.webp">
                            <img src="assets/images/testimonial-shanmugarajeshwaran.png" width="48"
                              height="48" alt="shanmugarajeshwaran" class="img-fluid w-48" loading="lazy">
                          </picture>
                          <!-- <img src="assets/images/testimonial-shanmugarajeshwaran.png" class="w-48" alt="teach" loading="lazy" /> -->
                        </figure>
                        <div class="d-flex flex-column text-left">
                          <div class="h4">Shanmugarajeshwaran</div>
                          <div class="h6">iOS Lead</div>
                        </div>
                      </div>
                      <figure class="d-flex ml-auto">
                        <picture>
                          <source type="image/webp" srcset="assets/images/quote.webp">
                          <img src="assets/images/quote.png" width="32" height="32" alt="quote"
                            class="img-fluid w-32" loading="lazy">
                        </picture>
                      </figure>
                    </div>
                    <p> I have been working at Codespot Foundation Solutions for over a year now. I am happy to say that I'm
                      a part of Codespot Foundation family - a company that is not only supportive, but also peaceful and
                      full of opportunities for technical skill and personal growth. </p>
                  </div>
                </div>
                <div slickItem>
                  <div class="testimonial-wrap">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <figure>
                          <picture>
                            <source type="image/webp" srcset="assets/images/testimonial-udhayakumar.webp">
                            <img src="assets/images/testimonial-udhayakumar.png" width="48" height="48"
                              alt="udhayakumar" class="img-fluid w-48" loading="lazy">
                          </picture>
                          <!-- <img src="assets/images/testimonial-udhayakumar.png" class="w-48" alt="teach" loading="lazy" /> -->
                        </figure>
                        <div class="d-flex flex-column text-left">
                          <div class="h4">Udhayakumar</div>
                          <div class="h6">Senior Software Developer</div>
                        </div>
                      </div>
                      <figure class="d-flex ml-auto">
                        <picture>
                          <source type="image/webp" srcset="assets/images/quote.webp">
                          <img src="assets/images/quote.png" width="32" height="32" alt="quote"
                            class="img-fluid w-32" loading="lazy">
                        </picture>
                      </figure>
                    </div>
                    <p> I have worked at Codespot Foundation Solutions for over a year and it has been an absolute pleasure.
                      The work environment is supportive and pleasant, the people are good and the work is meaningful. I
                      have made considerable strides in my career and the only thing I would say about this place is that
                      it's a great place to work </p>
                  </div>
                </div>
                <div slickItem>
                  <div class="testimonial-wrap">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <figure>
                          <picture>
                            <source type="image/webp" srcset="assets/images/testimonial-boobalan.webp">
                            <img src="assets/images/testimonial-boobalan.png" width="48" height="48"
                              alt="boobalan" class="img-fluid w-48" loading="lazy">
                          </picture>
                          <!-- <img src="assets/images/testimonial-boobalan.png" class="w-48" alt="teach" loading="lazy" /> -->
                        </figure>
                        <div class="d-flex flex-column text-left">
                          <div class="h4">Boobalan</div>
                          <div class="h6">Associate Software Developer</div>
                        </div>
                      </div>
                      <figure class="d-flex ml-auto">
                        <picture>
                          <source type="image/webp" srcset="assets/images/quote.webp">
                          <img src="assets/images/quote.png" width="32" height="32" alt="quote"
                            class="img-fluid w-32" loading="lazy">
                        </picture>
                      </figure>
                    </div>
                    <p> Since I started working here, Codespot Foundation has given me the autonomy to learn and work at my
                      own pace. It has shown me how a workplace should really be. Respect, trust, willingness to help, and
                      growth have all been experiences for me here. These qualities are what help a company shine as a great
                      organization. It’s all about creating a pleasant work culture. </p>
                  </div>
                </div>
                <div slickItem>
                  <div class="testimonial-wrap">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <figure>
                          <picture>
                            <source type="image/webp" srcset="assets/images/testimonial-saranraj.webp">
                            <img src="assets/images/testimonial-saranraj.png" width="48" height="48"
                              alt="saranraj" class="img-fluid w-48" loading="lazy">
                          </picture>
                          <!-- <img src="assets/images/testimonial-saranraj.png" class="w-48" alt="teach" loading="lazy" /> -->
                        </figure>
                        <div class="d-flex flex-column text-left">
                          <div class="h4">Saran Raj</div>
                          <div class="h6">Associate DevOps Engineer</div>
                        </div>
                      </div>
                      <figure class="d-flex ml-auto">
                        <picture>
                          <source type="image/webp" srcset="assets/images/quote.webp">
                          <img src="assets/images/quote.png" width="32" height="32" alt="quote"
                            class="img-fluid w-32" loading="lazy">
                        </picture>
                      </figure>
                    </div>
                    <p> A great team, great values, and unmatched spirit. That’s what I have experienced working as a part
                      of Codespot Foundation and everyone here has helped me cultivate these values within me. Everyday you
                      get a new experience and get to solve problems in the world. </p>
                  </div>
                </div>
                <div slickItem>
                  <div class="testimonial-wrap">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <figure>
                          <picture>
                            <source type="image/webp" srcset="assets/images/testimonial-boopathi.webp">
                            <img src="assets/images/testimonial-boopathi.png" width="48" height="48"
                              alt="boopathi" class="img-fluid w-48" loading="lazy" >
                          </picture>
                          <!-- <img src="assets/images/testimonial-boopathi.png" class="w-48" alt="teach" loading="lazy" /> -->
                        </figure>
                        <div class="d-flex flex-column text-left">
                          <div class="h4">Boopathi Krishnan</div>
                          <div class="h6">Associate Digital Marketer</div>
                        </div>
                      </div>
                      <figure class="d-flex ml-auto">
                        <picture>
                          <source type="image/webp" srcset="assets/images/quote.webp">
                          <img src="assets/images/quote.png" width="32" height="32" alt="quote"
                            class="img-fluid w-32" loading="lazy">
                        </picture>
                      </figure>
                    </div>
                    <p> Coming to the office does not feel like you’re coming to finish tasks. It’s like you’re part of
                      something bigger. You’re always trying to solve different relevant problems and you’re always on your
                      toes to learn new things. Each day is a whole new experience at Codespot Foundation. </p>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="roadmap pt-150" id="roadmap">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="title pb-70 pr-50 sticky">
            <div class="star right-bottom">
              <img src="assets/images/star.png" alt="star" width="35" height="35" class="img-fluid animate-blink" />
            </div>
            <h4>Journey</h4>
            <h2>
              <span>Codespot</span> Roadmap
            </h2>
            <p> We equip students in under-resourced high schools with the skills, experiences, and connections that
              together create access to careers in technology. </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="roadmap-sec active d-flex">
            <div class="d-flex flex-column pr-115">
              <h2>Q1</h2>
              <h4>2023</h4>
            </div>
            <div class="d-flex">
              <p> 1. Codespot Foundation Founded <br />
                <br /> 2. Community Outreach to Schools & Colleges across Coimbatore. <br />
                <br /> 3. Internship Program for Freshers-20-Person Batch.
              </p>
            </div>
          </div>
          <div class="roadmap-sec d-flex">
            <div class="d-flex flex-column pr-115">
              <h2>Q2</h2>
              <h4>2023</h4>
            </div>
            <div class="d-flex">
              <p> 1. Seminars & Awareness Programs at institutions across Coimbatore. <br />
                <br /> 2. Skill-based educational sessions for college students. <br />
                <br /> 3. Empowering 10,000+ students for a career in technology.
              </p>
            </div>
          </div>
          <div class="roadmap-sec d-flex">
            <div class="d-flex flex-column pr-115">
              <h2>Q3</h2>
              <h4>2023</h4>
            </div>
            <div class="d-flex">
              <p> 1. Talks & Seminars across institutions all over Tamil Nadu <br />
                <br /> 2. Upskilling programs for students across Tamil Nadu. <br />
                <br /> 3. Hackathons, Thought Leadership Talks & Community Events.
              </p>
            </div>
          </div>
          <div class="roadmap-sec d-flex">
            <div class="d-flex flex-column pr-115">
              <h2>Q4</h2>
              <h4>2023</h4>
            </div>
            <div class="d-flex">
              <p> 1. Codespot Online Learning Portal Launch <br />
                <br /> 2. Statewide outreach to 75 institutions, empowering 1,00,000+ students. <br />
                <br /> 3. Increase Tamil Nadu's talent pool by at least 3% in 2023.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- <section class="team-creators-section pt-150 text-center"><div class="container"><div class="row"><div class="col-sm-12"><div class="title d-inline-block"><h2 class="pb-6" >
              Meet our Team of <span>creators.</span></h2><p
              class="text-center pb-50"
            >
              We equip students in under-resourced high schools with the skills,
              experiences, and connections <br />
              that together create access to careers in technology.
            </p><div class="star left-bottom"><img
                src="assets/images/star.png"
                alt="star" width="35" height="35"
                class="img-fluid animate-blink"
              /></div><div class="star right-top"><img
                src="assets/images/star.png"
                alt="star" width="35" height="35"
                class="img-fluid animate-blink"
              /></div></div></div></div></div><div class="team-creators"><div class="container"><div class="row"><div class="col-md-3 sm-pb-40"><div class="title position-absolute"><h3>
                  "l love <span>Code Spot,</span><br />
                  learning is easier without <br />
                  any obstacles”
                </h3><a
                  href="#"
                  class="request_btn btn-style-one d-inline-block"
                  >View Teams
                  <i class="bi bi-arrow-right"></i></a></div></div></div></div><div class="container-fluid"><div class="row"><div class="offset-md-3 col-md-9 align-self-end"><div class="mb-32 video-testimonials"><div
                  slickContainer
                  #slickController="slick"
                  [slickConfig]="config"
                  class="ourteamcreators"
                ><ng-container><div slickItem><div class="testimonial-wrap"><figure><img
                            src="assets/images/team1.png"
                            class="img-fluid"
                            alt="testimonials"
                          /></figure><div
                          class="d-flex justify-content-center testimonial-caption flex-column"
                        ><h2>Vinayak Mahadev</h2><h4>Head of Content @ Codespot Foundation</h4></div><div class="social d-flex"><a href="#">Linked In</a><a href="#">Instagram</a></div></div></div><div slickItem><div class="testimonial-wrap"><figure><img
                            src="assets/images/team2.png"
                            class="img-fluid"
                            alt="testimonials"
                          /></figure><div
                          class="d-flex justify-content-center testimonial-caption flex-column"
                        ><h2>Vinayak Mahadev</h2><h4>Head of Content @ Codespot Foundation</h4></div><div class="social d-flex"><a href="#">Linked In</a><a href="#">Instagram</a></div></div></div><div slickItem><div class="testimonial-wrap"><figure><img
                            src="assets/images/team3.png"
                            class="img-fluid"
                            alt="testimonials"
                          /></figure><div
                          class="d-flex justify-content-center testimonial-caption flex-column"
                        ><h2>Vinayak Mahadev</h2><h4>Head of Content @ Codespot Foundation</h4></div><div class="social d-flex"><a href="#">Linked In</a><a href="#">Instagram</a></div></div></div><div slickItem><div class="testimonial-wrap"><figure><img
                            src="assets/images/team1.png"
                            class="img-fluid"
                            alt="testimonials"
                          /></figure><div
                          class="d-flex justify-content-center testimonial-caption flex-column"
                        ><h2>Vinayak Mahadev</h2><h4>Head of Content @ Codespot Foundation</h4></div><div class="social d-flex"><a href="#">Linked In</a><a href="#">Instagram</a></div></div></div><div slickItem><div class="testimonial-wrap"><figure><img
                            src="assets/images/team2.png"
                            class="img-fluid"
                            alt="testimonials"
                          /></figure><div
                          class="d-flex justify-content-center testimonial-caption flex-column"
                        ><h2>Vinayak Mahadev</h2><h4>Head of Content @ Codespot Foundation</h4></div><div class="social d-flex"><a href="#">Linked In</a><a href="#">Instagram</a></div></div></div><div slickItem><div class="testimonial-wrap"><figure><img
                            src="assets/images/team3.png"
                            class="img-fluid"
                            alt="testimonials"
                          /></figure><div
                          class="d-flex justify-content-center testimonial-caption flex-column"
                        ><h2>Vinayak Mahadev</h2><h4>Head of Content @ Codespot Foundation</h4></div><div class="social d-flex"><a href="#">Linked In</a><a href="#">Instagram</a></div></div></div></ng-container></div></div></div></div></div></div></section> -->
  <section class="gallery pt-150">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="title pb-70 pr-50">
            <div class="star left-top">
              <picture>
                <source type="image/webp" srcset="assets/images/star.webp">
                <img src="assets/images/star.png" width="35" height="35" alt="Star"
                  class="img-fluid animate-blink" loading="lazy">
              </picture>
            </div>
            <div class="h4">Our Gallery</div>
            <h2>Explore our learning <br /> journey <span>today!</span>
            </h2>
            <p> We equip students in under-resourced high schools with the skills, experiences, and connections that
              together create access to careers in technology. </p>
          </div>
          <div class="row">
            <div class="column">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery3.webp">
                <img src="assets/images/gallery3.png" width="262" height="289" alt="gallery3"
                  class="img-fluid w-100" loading="lazy">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets/images/gallery4.webp">
                <img src="assets/images/gallery4.png" width="261" height="187" alt="gallery4"
                  class="img-fluid w-100" loading="lazy">
              </picture>

              <!-- <img src="assets/images/gallery3.png" alt="gallery3" width="262" height="289" class="w-100" loading="lazy"/> -->
              <!-- <img src="assets/images/gallery4.png" alt="gallery4" width="261" height="187" class="w-100" loading="lazy"/> -->
            </div>
            <div class="column">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery5.webp">
                <img src="assets/images/gallery5.png" width="261" height="187" alt="gallery5"
                  class="img-fluid w-100" loading="lazy">
              </picture>
              <picture>
                <source type="image/webp" srcset="assets/images/gallery6.webp">
                <img src="assets/images/gallery6.png" width="261" height="240" alt="gallery6"
                  class="img-fluid w-100" loading="lazy">
              </picture>
              <!-- <img src="assets/images/gallery5.png" alt="gallery5" width="261" height="187" class="w-100" loading="lazy"/>
              <img src="assets/images/gallery6.png" alt="gallery6" width="261" height="240" class="w-100" loading="lazy"/> -->
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row align-items-end">
            <div class="column">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery1.webp">
                <img src="assets/images/gallery1.png" width="262" height="261" alt="gallery1"
                  class="img-fluid w-100" loading="lazy">
              </picture>
              <!-- <img src="assets/images/gallery1.png" alt="gallery1" width="262" height="261" class="w-100" loading="lazy" /> -->
            </div>
            <div class="column">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery2.webp">
                <img src="assets/images/gallery2.png" width="261" height="188" alt="gallery1"
                  class="img-fluid w-100" loading="lazy">
              </picture>
              <!-- <img src="assets/images/gallery2.png" alt="gallery2" width="261" height="188" class="w-100" loading="lazy" /> -->
            </div>
          </div>
          <div class="row">
            <div class="column column-full">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery7.webp">
                <img src="assets/images/gallery7.png" width="546" height="288" alt="gallery7"
                  class="img-fluid w-100" loading="lazy">
              </picture>
              <!-- <img src="assets/images/gallery7.png" alt="gallery7" width="546" height="288" class="w-100" loading="lazy" /> -->
            </div>
          </div>
          <div class="row">
            <div class="column">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery8.webp">
                <img src="assets/images/gallery8.png" width="262" height="289" alt="gallery8"
                  class="img-fluid w-100" loading="lazy">
              </picture>
              <!-- <img src="assets/images/gallery8.png" alt="gallery8" width="262" height="289" class="w-100" loading="lazy" /> -->
            </div>
            <div class="column">
              <picture>
                <source type="image/webp" srcset="assets/images/gallery9.webp">
                <img src="assets/images/gallery9.png" width="261" height="187" alt="gallery9"
                  class="img-fluid w-100" loading="lazy">
              </picture>
              <!-- <img src="assets/images/gallery9.png" alt="gallery9" width="261" height="187" class="w-100" loading="lazy" /> -->
            </div>
          </div>
          <!-- <div class="gallery-item"><img *ngFor="let item of items; index as i"
            [lightbox]="i"
            [src]="item.data.thumb"></div> -->
        </div>
      </div>
    </div>
  </section>

  <section class="contact pt-150 pb-150">
    <div class="container">
      <div class="row gap-4 gap-lg-0">
        <div class="col-lg-5">
          <div class="d-flex align-items-center justify-content-center flex-column h-100" id="contact">
            <div class="title d-inline-block">
              <h2>Get in Touch<span>.</span>
              </h2>
              <p class="pb-4"> We equip students in under-resourced <br /> high schools with the skills. </p>
              <div class="star">
                <picture>
                  <source type="image/webp" srcset="assets/images/star.webp">
                  <img src="assets/images/star.png" width="35" height="35" alt="Star"
                    class="img-fluid animate-blink" loading="lazy">
                </picture>
                <!-- <img src="assets/images/star.png" alt="star" width="35" height="35" class="img-fluid animate-blink" loading="lazy"  /> -->
              </div>
              <div class="star bottom-70">
                <picture>
                  <source type="image/webp" srcset="assets/images/star.webp">
                  <img src="assets/images/star.png" width="35" height="35" alt="Star"
                    class="img-fluid animate-blink" loading="lazy">
                </picture>
                <!-- <img src="assets/images/star.png" alt="star" width="35" height="35" class="img-fluid animate-blink" loading="lazy"  /> -->
              </div>
            </div>
            <figure class="d-flex mb-0">
              <picture>
                <source type="image/webp" srcset="assets/images/contact.webp">
                <img src="assets/images/contact.png" width="445" height="420" alt="Join our Team"
                  class="img-fluid contact-img animate-float" loading="lazy" >
              </picture>
              <!-- <img src="assets/images/contact.png" class="img-fluid contact-img" alt="Join our Team" loading="lazy"  /> -->
            </figure>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="contact-form">
            <h3 class="fs-4">Contact us</h3>
            <p >Share your thoughts and experiences with us</p>
            <div class="getintouch getintochform home-getintouchform">
              <form [formGroup]="contactForm" (ngSubmit)="createContactDetail()">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-floating">
                      <input type="text" class="form-control" id="floatingname" formControlName="name" (focus)="onFocus('name')" (blur)="onBlur()"
                        placeholder="Name"/>
                        <div *ngIf="contactForm. get('name')?.invalid && (contactForm.get ('name')?.dirty || contactForm.get('name') ?.touched) && focusedField !== 'name'" >
                          <div *ngIf="contactForm.get('name') ?.errors?.['required']" class="text-danger">Name is required.</div>
                          <div *ngIf="contactForm.get('name')?.errors?.['minlength']" class="text-danger">Name should be more than 3 letters!</div>
                      </div>

                      <label for="floatingname">Your Name</label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-floating">
                      <input type="text" class="form-control" id="floatingorganization" formControlName="organization" (focus)="onFocus('organization')" (blur)="onBlur()"
                        placeholder="Organization/College"  />
                        <div *ngIf="contactForm. get('organization')?.invalid && (contactForm.get ('organization')?.dirty || contactForm.get('organization') ?.touched) && focusedField !== 'organization'" >
                          <div *ngIf="contactForm.get('organization') ?.errors" class="text-danger">Organization is required.</div>
                      </div>

                      <label for="floatingorganization">Organization/College</label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-floating">
                      <input type="email" class="form-control" id="floatingemail" placeholder="Email"
                        formControlName="email" (focus)="onFocus('email')" (blur)="onBlur()"  />
                        <div *ngIf="contactForm. get('email')?.invalid && (contactForm.get ('email')?.dirty || contactForm.get('email') ?.touched) && focusedField !== 'email'" >
                          <div *ngIf="contactForm.get('email') ?.hasError('required')" class="text-danger">Email is required.</div>
                          <div *ngIf="contactForm.get('email')?.hasError('pattern') && contactForm.get('email')?.value && contactForm.get('email')?.invalid" class="text-danger">Invalid email format.</div>
                      </div>

                      <label for="floatingemail">Email</label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-floating">
                      <input type="number" placeholder="Phone Number" (keypress)="numericOnly($event)"
                        id="floatingphone_number" formControlName="phone_number" class="form-control" (focus)="onFocus('phone_number')" (blur)="onBlur()" />
                        <div *ngIf="contactForm.get('phone_number')?.invalid && (contactForm.get('phone_number')?.dirty || contactForm.get('phone_number')?.touched) && focusedField !== 'phone_number'">
                          <div *ngIf="contactForm.get('phone_number')?.errors?.['required']" class="text-danger">Phone Number is required.</div>
                          <div *ngIf="contactForm.get('phone_number')?.errors?.['minlength']" class="text-danger">Phone number must be at least 10 digits.</div>
                          <div *ngIf="contactForm.get('phone_number')?.hasError('pattern') && contactForm.get('phone_number')?.value && contactForm.get('phone_number')?.invalid"class="text-danger">Invalid phone number format.</div>
                          <!-- <div *ngIf="contactForm.get('phone_number')?.errors?.['pattern']" class="text-danger">Phone number must be 10 to 16 digits long.</div> -->
                        </div>

                      <label for="floatingphone_number">Phone Number</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-checkbox">
                      <div class="h4">Enquiring for ?</div>
                      <div class="d-flex justify-content-between" [ngClass]="{
                          'is-invalid': submitted && f['enquiry'].errors
                        }">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" formControlName="enquiry" id="inlineCheckbox1"
                            value="Internship" checked />
                          <label class="form-check-label" for="inlineCheckbox1">Internship</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" formControlName="enquiry" id="inlineCheckbox2"
                            value="Placement" />
                          <label class="form-check-label" for="inlineCheckbox2">Placement</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" formControlName="enquiry" id="inlineCheckbox3"
                            value="Certification Course" />
                          <label class="form-check-label" for="inlineCheckbox3">Certification Course</label>
                        </div>
                      </div>
                      <div *ngIf="submitted && f['enquiry'].errors" class="invalid-feedback">
                        <div *ngIf="contactForm.get('enquiry') ?.errors"> Please select one </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-floating">
                      <textarea class="form-control" placeholder="Your Message" id="floatingTextarea2"
                        style="height: 70px" formControlName="message" [ngClass]="{
                          'is-invalid': submitted && f['message'].errors
                        }"></textarea>

                      <label for="floatingTextarea2">Your Message</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="text-right">
                      <button class="request_btn" *ngIf="!isProcessing" [disabled]="contactForm.invalid"
                      [ngClass]="{
                        'opacity-25 cursor-not-allowed': contactForm.invalid ,
                        'opacity-100': !contactForm.invalid }">Submit</button>

                        <button class="request_btn" *ngIf="isProcessing" >
                        <svg aria-hidden="true" role="status" width="20px" class="inline  me-1 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                      </svg>
                        Submit</button>

                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!-- <div class="bg-white interested-form ">
                <div class="d-flex flex-column">
                    <h2 class="mb-1">Contact us</h2>
                    <p class="p-0 m-0">Share your thoughts and experiences with us</p>
                </div>
                <form>
                    <div class="row mt-3 mt-md-4 pt-2 px-0 pb-0 popup-content">
                        <div class="col-12 col-md-6 mb-4 popup-input">
                            <input type="text" class="interest-form-control" placeholder="" id="fname">
                            <label for="fname">Frist Name</label>
                        </div>
                        <div class="col-12 col-md-6 mb-4 popup-input">
                            <input type="text" class="interest-form-control" placeholder="" id="lname">
                            <label for="lname">Last Name</label>
                        </div>
                        <div class="col-12 col-md-6 mb-4 popup-input">
                            <input type="number" class="interest-form-control" placeholder="" id="mobile-number">
                            <label for="mobile-number">Mobile number</label>
                        </div>
                        <div class="col-12 col-md-6 mb-4 popup-input">
                            <input type="email" class="interest-form-control" placeholder="" id="email">
                            <label for="email">Email address</label>
                        </div>
                        <div class="col-12 col-md-6 mb-4">
                            <select id="select-medium" class="interest-form-control bg-white " >
                                <option>Select Course</option>
                                <option value="1">Tamil</option>
                                <option value="2">English</option>
                            </select>
                        </div>
                        <div class="col-12 col-md-6 mb-4">
                            <select id="select-medium" class="interest-form-control bg-white " >
                                <option>Select medium</option>
                                <option value="1">Tamil</option>
                                <option value="2">English</option>
                            </select>
                        </div>
                        <div class="col-12 mb-4">
                            <textarea rows="5" placeholder="Enter your message"></textarea>
                        </div>
                        <div class="col-12">
                            <button>Submit</button>
                        </div>
                    </div>
                </form>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-footer-new></app-footer-new>
</section>
<div class="modal" [ngClass]="showModal ? 'openModal' : 'hidden'" id="dataid" tabindex="-1" aria-labelledby="dataVideosLabel" aria-hidden="true"  >
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="stopVideo(currentVideoId)" >
      </button>
    <iframe id="videoYT" width="100%" height="450" [src]="currentUrl | safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
  </div>
  </div>


<!--end-->
